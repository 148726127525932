import React from 'react';
import { styled } from '../../Theme';

function BoxIcon() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <path
        fill="#484848"
        d="M9.00109298,1 C9.37986503,1 9.72612815,1.21400238 9.89552017,1.5527864 L11.8994518,5.56022533 C11.9655098,5.69276558 11.9993355,5.83794859 12.002186,5.97994028 L12.001093,14 C12.001093,14.5128358 11.6150528,14.9355072 11.1177141,14.9932723 L11.001093,15 L1.00109298,15 C0.488257136,15 0.0655858144,14.6139598 0.0078207064,14.1166211 L0.00109297508,14 L0,5.97994028 C0.00250757158,5.85502778 0.0289870163,5.72764551 0.0829707328,5.60350902 L2.10666578,1.5527864 C2.2760578,1.21400238 2.62232092,1 3.00109298,1 L9.00109298,1 Z M11.001093,7 L7.001,7 L7.00109298,10 L5.00109298,10 L5.001,7 L1.00109298,7 L1.00109298,14 L11.001093,14 L11.001093,7 Z M5.501,2 L3.00109298,2 L1.00109298,6 L5.00109298,6 L5.501,2 Z M9.00109298,2 L6.501,2 L7.002,6 L11.001093,6 L9.00109298,2 Z"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '12px',
});

export default BoxIcon;
