import React, { useState, useRef } from 'react';
import { styled, theme } from '../../Theme';
import ChevronIcon from './../Icons/ChevronIcon';
import IconSwitch from '../../Shared/Icons/IconsSwitch';
import { pseudo } from '@glitz/core';
import { StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import ChevronIconBolder from '../Icons/ChevronIconBolder';

type Value = {
  icon?: string;
  heading?: string;
  subHeading?: string;
  value?: string;
};

export type ValueDropDownProps = StyledProps & {
  values: Value[];
  currentValue: Value;
  setSelectedValue: (value: string) => void;
  noValuesMsg?: string;
  isProductPageDropdown?: boolean;
};

const ValueDropDown: React.FC<ValueDropDownProps> = ({
  values,
  currentValue,
  setSelectedValue,
  noValuesMsg,
  isProductPageDropdown = false,
  compose,
}) => {
  const [showValues, toggleValues] = useState(false);
  const ref = useRef(null);

  const dropdownContainerStyleArray: StyleOrStyleArray = [
    showValues ? ShowValuesDropdownContainerStyle : {},
    isProductPageDropdown && showValues
      ? ProductPageDropdownContainerStyle
      : {},
  ];

  const dropdownStyleArray: StyleOrStyleArray = [
    isProductPageDropdown ? ProductPageDropdownStyle : {},
  ];

  const currentValueStyleArray: StyleOrStyleArray = [
    isProductPageDropdown ? ProductPageCurrentValueStyle : {},
  ];

  const descriptionStyleArray: StyleOrStyleArray = [
    isProductPageDropdown ? ProductPageDescriptionStyle : {},
  ];

  return values.length > 0 ? (
    <DropDownContainer
      tabIndex={0}
      onBlur={e => {
        if (!e.currentTarget.contains(e.relatedTarget as Element)) {
          toggleValues(false);
        }
      }}
      css={
        compose
          ? compose(dropdownContainerStyleArray)
          : dropdownContainerStyleArray
      }
    >
      <ValueFlex onClick={() => toggleValues(prev => !prev)} ref={ref}>
        <Left
          css={
            isProductPageDropdown
              ? {}
              : { margin: { y: theme.spacing(2), x: theme.spacing(4) } }
          }
        >
          {currentValue.icon && (
            <IconWrapper>{IconSwitch(currentValue.icon)}</IconWrapper>
          )}
          <CurrentValue css={currentValueStyleArray}>
            {currentValue.heading}
          </CurrentValue>
          {currentValue.subHeading && (
            <Description css={descriptionStyleArray}>
              {currentValue.subHeading}
            </Description>
          )}
        </Left>
        {isProductPageDropdown ? (
          <StyledBolderArrow
            css={{ transform: showValues ? 'rotate(180deg)' : 'none' }}
          />
        ) : (
          <StyledArrow
            css={{ transform: showValues ? 'rotate(180deg)' : 'none' }}
          />
        )}
      </ValueFlex>
      {showValues && (
        <DropDown css={dropdownStyleArray}>
          {values &&
            values.length > 0 &&
            values.map((v: Value, index: number) => (
              <DropDownButton
                key={v.value}
                onClick={() => {
                  setSelectedValue(v.value || index.toString());
                  toggleValues(false);
                }}
                css={{
                  fontWeight:
                    v.value === currentValue.value ? 'bold' : 'inherit',
                }}
              >
                <OptionContainer>
                  <>
                    <OptionHeading>
                      {v.icon && (
                        <IconWrapper>{IconSwitch(v.icon)}</IconWrapper>
                      )}
                      {v.heading}
                    </OptionHeading>
                    {v.subHeading && (
                      <OptionHeading>{v.subHeading}</OptionHeading>
                    )}
                  </>
                </OptionContainer>
              </DropDownButton>
            ))}
        </DropDown>
      )}
    </DropDownContainer>
  ) : noValuesMsg ? (
    <ErrorMsg>{noValuesMsg}</ErrorMsg>
  ) : (
    <React.Fragment />
  );
};

export default styled(ValueDropDown);

const Left = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const Description = styled.div({
  flexBasis: '100%',
  color: theme.black,
  lineHeight: theme.lineHeight.moreRelaxed,
  fontSize: theme.alpha,
});

const DropDownContainer = styled.div({
  width: '100%',
  position: 'relative',
  height: '100%',
  display: 'block',
  textAlign: 'left',
  border: {
    xy: { width: theme.spacing(0.5), color: theme.lightGray },
  },
  ':hover': {
    border: {
      xy: { color: theme.gray },
    },
  },
});

const ValueFlex = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  height: '100%',
  alignItems: 'center',
  cursor: 'pointer',
  ...pseudo(':hover span', {
    textDecoration: 'underline',
  }),
});

const DropDownButton = styled.button({
  width: '-webkit-fill-available',
  textTransform: 'capitalize',
  font: { size: theme.alpha },
  textAlign: 'left',
  ':focus': {
    boxShadow: `0 0 0 2px ${theme.lightGray}`,
  },
  ...pseudo(':not(:last-of-type)', {
    marginBottom: theme.spacing(4),
  }),
  ...pseudo(':focus', {
    border: {
      xy: {
        width: theme.none,
      },
    },
  }),
});

const DropDown = styled.div({
  padding: { xy: theme.spacing(4) },
  height: 'auto',
  maxHeight: theme.spacing(50),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: {
    xy: { width: theme.spacing(0.5), color: theme.lightGray },
  },
  backgroundColor: 'white',
  position: 'absolute',
  width: `calc(100% + ${theme.spacing(1)}px)`,
  left: -theme.spacing(0.5),
  zIndex: theme.zIndex.DropDown,
  overflow: 'auto',
});

const IconWrapper = styled.span({
  margin: { right: theme.spacing(2) },
});

const CurrentValue = styled.span({
  alignSelf: 'center',
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
  textTransform: 'capitalize',
  font: {
    size: theme.alpha,
    weight: theme.fontWeight.bold,
  },
});

const StyledArrow = styled(ChevronIcon, {
  height: theme.spacing(4),
  width: theme.spacing(4),
  color: theme.black,
  fill: theme.black,
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
  },
  margin: { right: theme.spacing(4) },
});

const StyledBolderArrow = styled(ChevronIconBolder, {
  height: theme.spacing(6),
  width: theme.spacing(6.25),
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
  },
});

const OptionContainer = styled.div({
  alignItems: 'center',
  ':hover': {
    textDecoration: 'underline',
  },
  color: theme.black,
});

const OptionHeading = styled.div({
  display: 'flex',
});

const ErrorMsg = styled.p({
  color: theme.errorText,
});

const ProductPageDropdownStyle: Style = {
  border: {
    xy: { width: theme.spacing(0.25), color: theme.fairGray },
    radius: theme.spacing(),
  },
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  left: -theme.spacing(0.25),
  width: `calc(100% + ${theme.spacing(0.5)}px)`,
  top: '100%',
  ':hover': {
    border: {
      xy: { color: theme.gray },
    },
  },
};

const ProductPageCurrentValueStyle: Style = {
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
};

const ProductPageDescriptionStyle: Style = {
  lineHeight: theme.lineHeight.snug,
  font: {
    size: theme.beta,
    weight: theme.fontWeight.bolder,
  },
};

const ShowValuesDropdownContainerStyle: Style = {
  backgroundColor: theme.lightGray,
};

const ProductPageDropdownContainerStyle: Style = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};
