import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { theme } from '../Theme';
import { media, pseudo } from '@glitz/core';
import ChevronIcon from '../Shared/Icons/ChevronIcon';
import useMedia from '../Shared/Hooks/useMedia';
import QuantityMinusIcon from '../Shared/Icons/QuantityMinusIcon';
import QuantityPlusIcon from '../Shared/Icons/QuantityPlusIcon';

type QuantityInput = StyledProps & {
  updateState: (value: number) => void;
  quantity: number;
  onProductPage?: boolean;
};

function canDecreaseQty(quantity: number) {
  return quantity > 1;
}

function validateInvalidInputOnBlur(input: number) {
  if (input < 1 || isNaN(input)) return 1;
  return input;
}

const QuantityInput: React.FC<QuantityInput> = ({
  updateState,
  quantity,
  onProductPage = true,
  compose,
}) => {
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const onDecreaseButtonClick = () => {
    quantity >= 2 && updateState(quantity - 1);
  };

  const onIncreaseButtonClick = () => {
    quantity >= 1 && updateState(quantity + 1);
  };

  const containerStyleArray: StyleOrStyleArray = [
    onProductPage ? ProductPageContainerStyle : {},
  ];

  const quantityStyleArray: StyleOrStyleArray = [
    onProductPage
      ? ProductPageQuantityStyle
      : isDesktop
      ? { width: '50%' }
      : { width: '100%', padding: { y: theme.spacing(4) } },
  ];

  return (
    <Container
      css={compose ? compose(containerStyleArray) : containerStyleArray}
    >
      {onProductPage && (
        <DecreaseButton
          disabled={!canDecreaseQty(quantity)}
          onClick={onDecreaseButtonClick}
          css={canDecreaseQty(quantity) ? {} : disabledDecreaseButton}
        >
          <MinusIcon />
        </DecreaseButton>
      )}
      <Quantity
        id="input"
        min={1}
        type="number"
        value={quantity}
        onChange={evt => updateState(parseInt(evt.target.value))}
        onBlur={evt =>
          updateState(validateInvalidInputOnBlur(parseInt(evt.target.value)))
        }
        css={quantityStyleArray}
      />
      {onProductPage && (
        <IncreaseButton onClick={onIncreaseButtonClick}>
          <PlusIcon />
        </IncreaseButton>
      )}
      {isDesktop && !onProductPage && (
        <ArrowContainer>
          <Arrow onClick={onIncreaseButtonClick}>
            <StyledArrowUp />
          </Arrow>
          <Arrow
            disabled={!canDecreaseQty(quantity)}
            onClick={onDecreaseButtonClick}
          >
            <StyledArrow css={canDecreaseQty(quantity) ? {} : disabledArrow} />
          </Arrow>
        </ArrowContainer>
      )}
    </Container>
  );
};

export const QntInput = styled(QuantityInput);

const Container = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const DecreaseButton = styled.button({});

const IncreaseButton = styled.button({});

const MinusIcon = styled(QuantityMinusIcon, {
  width: theme.spacing(4.25),
  height: theme.spacing(4),
});

const PlusIcon = styled(QuantityPlusIcon, {
  width: theme.spacing(4.25),
  height: theme.spacing(4),
});

const Quantity = styled.input({
  width: '100%',
  backgroundColor: 'transparent',
  textAlign: 'center',
  font: {
    size: theme.psi,
    weight: theme.fontWeight.bold,
  },
  lineHeight: theme.lineHeight.mediumSnug,
  color: theme.black,

  ':active': {
    outline: {
      width: 0,
    },
  },
  ':focus': {
    outline: {
      width: 0,
    },
  },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    border: {
      right: {
        style: 'solid',
        width: theme.spacing(0.5),
        color: theme.lightGray,
      },
    },
  }),
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none',
  }),
});

const ArrowContainer = styled.div({
  display: 'flex',
  width: '50%',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  backgroundColor: theme.lightGray,
});

const Arrow = styled.button({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.gray,
  },
  ':disabled': {
    backgroundColor: theme.thinGray,
    cursor: 'not-allowed',
  },
});

const StyledArrow = styled(ChevronIcon, {
  width: theme.spacing(3),
  height: theme.spacing(3),
  fill: theme.black,
  cursor: 'pointer',
  margin: { y: 0, x: 'auto' },
});

const StyledArrowUp = styled(StyledArrow, {
  transform: 'rotate(180deg)',
});

const disabledArrow: StyleOrStyleArray = {
  fill: theme.darkGray,
  cursor: 'not-allowed',
};

const disabledDecreaseButton: StyleOrStyleArray = {
  cursor: 'not-allowed',
};

const ProductPageContainerStyle: Style = {
  padding: { x: theme.spacing(3), y: theme.spacing(2.5) },
  border: {
    radius: theme.spacing(),
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.blackAndWhite,
    },
  },
};

const ProductPageQuantityStyle: Style = {
  width: '100%',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    border: {
      right: {
        style: 'none',
      },
    },
  }),
};
