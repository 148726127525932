import React from 'react';
import { styled } from '../../Theme';

function PalletIcon() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <path
        fill="#484848"
        d="M11,13 C11.5128358,13 11.9355072,13.3860402 11.9932723,13.8833789 L12,14 L12,15 C12,15.5128358 11.6139598,15.9355072 11.1166211,15.9932723 L11,16 L1,16 C0.487164161,16 0.0644928393,15.6139598 0.00672773133,15.1166211 L0,15 L0,14 C0,13.4871642 0.38604019,13.0644928 0.883378875,13.0067277 L1,13 L11,13 Z M11,14 L1,14 L1,15 L11,15 L11,14 Z M10,1 C10.5128358,1 10.9355072,1.38604019 10.9932723,1.88337887 L11,2 L11,11 C11,11.5128358 10.6139598,11.9355072 10.1166211,11.9932723 L10,12 L2,12 C1.48716416,12 1.06449284,11.6139598 1.00672773,11.1166211 L1,11 L1,2 C1,1.48716416 1.38604019,1.06449284 1.88337887,1.00672773 L2,1 L10,1 Z M3.25,7 L2,7 L2,11 L5.5,11 L5.5,7 L4.25,7 L4.25,9 L3.25,9 L3.25,7 Z M8.75,9 L7.75,9 L7.75,7 L6.5,7 L6.5,11 L10,11 L10,7 L8.75,7 L8.75,9 Z M3.25,2 L2,2 L2,6 L5.5,6 L5.5,2 L4.25,2 L4.25,4 L3.25,4 L3.25,2 Z M7.75,2 L6.5,2 L6.5,6 L10,6 L10,2 L8.75,2 L8.75,4 L7.75,4 L7.75,2 Z"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '12px',
});

export default PalletIcon;
