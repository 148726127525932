import React, { useRef } from 'react';
import useOnScreen from '../../Shared/Hooks/useOnScreen';
import { StyledProps } from '@glitz/react';
import { styled } from '../../Theme';

type PropType = StyledProps & {
  src: string;
  alt: string;
  imgFit?: boolean;
  onLoad?: () => void;
  title?: string;
  lazy?: boolean;
  externalRef?: React.RefObject<HTMLDivElement>;
};

const Image = ({
  src,
  alt = '',
  onLoad,
  title = '',
  lazy = true,
  externalRef,
  compose,
}: PropType) => {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref: externalRef ? externalRef : ref });
  return onScreen || !lazy ? (
    <styled.Img
      css={compose()}
      src={src}
      alt={alt}
      title={title}
      aria-label={alt}
      onLoad={onLoad}
    />
  ) : (
    <Placeholder ref={ref} />
  );
};

const Placeholder = styled.span({
  display: 'block',
});

export default Image;
