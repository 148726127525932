const imagePreset = {
  product: {
    card: '?preset=product-card-desktop',
  },
  category: {},
  productPage: {
    desktopMain: '?preset=productpage-desktop-main-images',
    desktopMainSingle: '?preset=productpage-desktop-main-single',
    mobileMain: '?preset=productpage-mobile-main-images',
    tabletMain: '?preset=productpage-tablet-main-images',
    desktopThumbnails: '?preset=productpage-desktop-thumbnails',
  },
  menu: {
    item: '?preset=megamenu-card',
    itemWithBackground: '?preset=megamenu-card-with-bg',
  },
};

export default imagePreset;
