import React from 'react';
import { styled } from '../../Theme';

function PackageBox() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <path
        fill="#484848"
        d="M0,0 L12,0 L12,16 L0,16 L0,0 Z M10.999,14 L1,14 L1,15 L11,15 L10.999,14 Z M11,7 L1,7 L1,13 L11,13 L11,7 Z M11,1 L1,1 L1,6 L10.999,6 L11,1 Z M8.5,2 C9.32842712,2 10,2.67157288 10,3.5 C10,4.27969612 9.40511192,4.92044868 8.64446001,4.99313342 L8.5,5 L3.5,5 C2.67157288,5 2,4.32842712 2,3.5 C2,2.72030388 2.59488808,2.07955132 3.35553999,2.00686658 L3.5,2 L8.5,2 Z M8.5,3 L3.5,3 C3.22385763,3 3,3.22385763 3,3.5 C3,3.74545989 3.17687516,3.94960837 3.41012437,3.99194433 L3.5,4 L8.5,4 C8.77614237,4 9,3.77614237 9,3.5 C9,3.25454011 8.82312484,3.05039163 8.58987563,3.00805567 L8.5,3 Z"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '12px',
});

export default PackageBox;
