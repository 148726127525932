import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const ChevronIconBolder = ({ compose }: StyledProps) => (
  <styled.Svg
    css={compose()}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52734 9L12.5273 15L18.5273 9"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </styled.Svg>
);

export default styled(ChevronIconBolder);
