import React from 'react';
import { styled } from '../../Theme';

function PackageBox() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <path
        fill="#484848"
        d="M9,0 C10.6568542,0 12,1.34314575 12,3 L12,13 C12,14.6568542 10.6568542,16 9,16 L3,16 C1.34314575,16 0,14.6568542 0,13 L0,3 C0,1.34314575 1.34314575,0 3,0 L9,0 Z M9,1 L3,1 C1.9456382,1 1.08183488,1.81587779 1.00548574,2.85073766 L1,3 L1,13 C1,14.0543618 1.81587779,14.9181651 2.85073766,14.9945143 L3,15 L9,15 C10.0543618,15 10.9181651,14.1841222 10.9945143,13.1492623 L11,13 L11,3 C11,1.9456382 10.1841222,1.08183488 9.14926234,1.00548574 L9,1 Z M9,6 C9.55228475,6 10,6.44771525 10,7 L10,13 C10,13.5522847 9.55228475,14 9,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,7 C2,6.44771525 2.44771525,6 3,6 L9,6 Z M9,7 L3,7 L3,13 L9,13 L9,7 Z M8.5,2 C9.32842712,2 10,2.67157288 10,3.5 C10,4.27969612 9.40511192,4.92044868 8.64446001,4.99313342 L8.5,5 L3.5,5 C2.67157288,5 2,4.32842712 2,3.5 C2,2.72030388 2.59488808,2.07955132 3.35553999,2.00686658 L3.5,2 L8.5,2 Z M8.5,3 L3.5,3 C3.22385763,3 3,3.22385763 3,3.5 C3,3.74545989 3.17687516,3.94960837 3.41012437,3.99194433 L3.5,4 L8.5,4 C8.77614237,4 9,3.77614237 9,3.5 C9,3.25454011 8.82312484,3.05039163 8.58987563,3.00805567 L8.5,3 Z"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '12px',
});

export default PackageBox;
