import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const QuantityMinusIcon = ({ compose }: StyledProps) => (
  <styled.Svg
    css={compose()}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.86072 8H13.1941"
      stroke="#004C97"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </styled.Svg>
);

export default styled(QuantityMinusIcon);
