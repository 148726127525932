import React from 'react';
import BoxIcon from './box';
import PackageBox from './PackageBox';
import PackageBag from './PackageBag';
import PalletIcon from './PalletIcon';

export default function IconSwitch(key: string) {
  switch (key) {
    case '2':
    case '4':
    case '3':
      return <PackageBag />;
    case '6':
      return <PackageBox />;
    case '90':
      return <BoxIcon />;
    case '92':
      return <PalletIcon />;
    default:
      return <PackageBox />;
  }
}
