import React from 'react';
import { theme } from '../../Theme';
import { styled, StyledProps } from '@glitz/react';
import { media } from '@glitz/core';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import { Style, StyleOrStyleArray } from '@glitz/type';

type PropTypes = StyledProps & {
  loweredPrice?: string;
  price: string;
  isRow?: boolean;
  isSmall?: boolean;
  isProductPage?: boolean;
  isOutOfStock?: boolean;
};

function Price({
  loweredPrice,
  price,
  isRow,
  isSmall,
  isProductPage = false,
  isOutOfStock = false,
  compose,
}: PropTypes) {
  const {
    translations: { 'productPage/exVat': exVatLabel },
  } = useAppSettingsData();

  const priceTextStyleArray: StyleOrStyleArray = [
    isSmall ? PriceTextSmallCss : {},
    isProductPage ? ProductPagePriceTextStyle : {},
  ];

  const exVatStyleArray: StyleOrStyleArray = [
    isProductPage ? ProductPageExVatStyle : {},
  ];

  const wrapperStyleArray: StyleOrStyleArray = [
    isProductPage ? ProductPageWrapperStyle : {},
    isOutOfStock ? ProductPageWrapperOutOfStockStyle : {},
  ];

  return (
    <Wrapper css={wrapperStyleArray}>
      {loweredPrice && loweredPrice !== price ? (
        isProductPage ? (
          <LoweredPriceWrapper>
            <PriceText css={priceTextStyleArray}>{loweredPrice}</PriceText>
            <BasePrice>{price}</BasePrice>
          </LoweredPriceWrapper>
        ) : (
          <StyledLoweredPrice css={compose()} isRow={isRow}>
            <OldPrice css={isSmall ? OldPriceSmallCss : {}}>{price}</OldPrice>
            <PriceTextRed css={isSmall ? PriceTextSmallCss : {}}>
              {loweredPrice}
            </PriceTextRed>
          </StyledLoweredPrice>
        )
      ) : (
        <PriceText css={priceTextStyleArray}>{price}</PriceText>
      )}
      <ExVat css={exVatStyleArray}>{exVatLabel}</ExVat>
    </Wrapper>
  );
}

const ExVat = styled.div({
  fontSize: theme.alpha,
});

const PriceTextSmallCss = {
  fontSize: theme.alpha,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.gamma,
  }),
};

const OldPriceSmallCss = {
  fontSize: theme.tau,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.alpha,
  }),
};

const Wrapper = styled.div({
  margin: { y: 'auto' },
});

const PriceText = styled.p({
  marginBottom: theme.none,
  font: {
    size: theme.delta,
    weight: theme.fontWeight.normal,
  },
  lineHeight: theme.lineHeight.moreSnug,
  color: theme.black,
  width: 'auto',
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: theme.epsilon,
      weight: theme.fontWeight.bold,
    },
    lineHeight: theme.lineHeight.snug,
  }),
});

const BasePrice = styled.span({
  font: {
    size: theme.gamma,
    weight: theme.fontWeight.bolder,
  },
  lineHeight: theme.lineHeight.snug,
  textDecorationLine: 'line-through',
  color: theme.black,
});

const PriceTextRed = styled.p({
  color: 'red',
  marginBottom: theme.none,
  font: {
    size: theme.delta,
    weight: theme.fontWeight.normal,
  },
  lineHeight: theme.lineHeight.moreSnug,
  width: 'auto',
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: theme.epsilon,
      weight: theme.fontWeight.bold,
    },
    lineHeight: theme.lineHeight.snug,
  }),
});

const LoweredPrice = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const LoweredPriceWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: `${theme.spacing(4)}px`,
});

const OldPrice = styled.span({
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
  color: theme.black,
  textDecoration: 'line-through',
  marginRight: `${theme.spacing(2)}px`,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.gamma,
  }),
});

const StyledLoweredPrice = React.memo(
  styled(
    ({
      compose,
      isRow = false,
      children,
      ...restProps
    }: { isRow?: boolean; children: React.ReactNode } & React.HTMLAttributes<
      HTMLDivElement
    > &
      StyledProps) => (
      <LoweredPrice
        css={compose({
          ...(isRow && {
            flexDirection: 'row-reverse',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }),
        })}
        {...restProps}
      >
        {children}
      </LoweredPrice>
    )
  )
);

const ProductPagePriceTextStyle: Style = {
  font: {
    size: theme.mu,
    weight: theme.fontWeight.bold,
  },
  lineHeight: theme.lineHeight.mediumSnug,
  color: theme.blueDark,
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: theme.mu,
      weight: theme.fontWeight.bold,
    },
    lineHeight: theme.lineHeight.mediumSnug,
    color: theme.blueDark,
  }),
};

const ProductPageExVatStyle: Style = {
  lineHeight: theme.lineHeight.mediumSnug,
  color: theme.black,
  font: {
    size: theme.beta,
    weight: theme.fontWeight.bolder,
  },
};

const ProductPageWrapperStyle: Style = {
  margin: { y: theme.spacing(10) },

  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    margin: { y: theme.spacing(6) },
  }),
};

const ProductPageWrapperOutOfStockStyle: Style = {
  marginBottom: theme.spacing(6),
};

export default styled(Price);
